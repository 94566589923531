<template>
  <div v-if="isMobile" class="scroll-wrapper">
    <vue-scroll
      :ops="ops"
      ref="scrollBarContent"
      @handle-scroll="handleMobileScroll"
    >
      <div class="container" ref="scrollMobileContent">
        <div class="scrollTest" ref="scroll0" id="scroll0">
          <AppDirectory />
        </div>
        <div ref="scrollTop"></div>
        <div class="scrollTest" ref="scroll1" id="scroll1">
          <AppPartner />
        </div>
        <div ref="scrollBottom"></div>
        <div class="scrollTest" ref="scroll2">
          <AppSoul />
        </div>
        <Footer />
      </div>
    </vue-scroll>
    
    <div class="features directory">
      <div
        class="features-item"
        v-for="(item, key) in featuresList"
        :key="key"
        @click="scrollOffset(key)"
        :class="{ featuresStyle: featuresIndex === key }"
      >
        <span> {{ item }} </span>
      </div>
      <img src="../../assets/img/shui.png" alt="" />
    </div>
  </div>
  <div v-else class="container" id="anchor0">
    <div class="features directory">
      <div
        class="features-item"
        v-for="(item, key) in featuresList"
        :key="key"
        @click="scrollTo(key)"
        :class="{ featuresStyle: featuresIndex === key }"
      >
        <span class="features-name"> {{ item }} </span>
      </div>
      <img src="../../assets/img/shui.png" alt="" />
    </div>
    <div>

    </div>
    <Occupation />
    <Partner />
    <div style="padding: 70px 0;margin-bottom: 140px;">
      <Soul />
    </div>
    <Footer />
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import { isMobile } from "@/utils/util";
import Footer from "@/components/Footer.vue";
import AppDirectory from "./components/AppDirectory";
import AppPartner from "./components/AppPartner";
import AppSoul from "./components/AppSoul";

import Occupation from "./components/Occupation.vue";
import Partner from "./components/Partner.vue";
import Soul from "./components/Soul.vue";

export default {
  name: "Features",
  components: {
    Footer,
    Partner,
    Soul,
    AppDirectory,
    AppPartner,
    AppSoul,
    Occupation,
  },
  data() {
    return {
      featuresList: ["職業", "従魔", "元魂"],
      featuresIndex: 0,
      stageActiveKey: 0,
      scrollHeight: 0,
      scrollHeightList: [],
      soulStageActive: 0,
      isMobile,
      activeBallKey: 0,
      scrollContent: null,
      ops: {
        vuescroll: {
          detectResize: false,
        },
        scrollPanel: {
          // 禁止x方向滚动
          scrollingX: false,
        },
        rail: {},
        bar: {
          background: "transparent",
        },
      },
    };
  },
  methods: {
    ...mapMutations(["increment"]),
    setStage(key) {
      this.stageActiveKey = key;
    },
    setScrollHeight() {
      if (this.scrollHeight === 0) {
        const scrollHeight =
          document.body.scrollHeight - document.body.offsetHeight;
        console.log(scrollHeight);
        this.scrollHeight = scrollHeight;
        this.scrollHeightList = [
          (scrollHeight * 0.2).toFixed(0),
          (scrollHeight * 0.7).toFixed(0),
        ];
      }
    },
    scrollTo(state) {
      console.log(1);
      this.setScrollHeight();
      this.featuresIndex = state;
      switch (state) {
        case 0:
          window.scrollTo(0, 0);
          break;
        case 1:
          console.log(this.scrollHeightList);
          window.scrollTo(0, Number(this.scrollHeightList[0]) + 300);
          break;
        case 2:
          window.scrollTo(0, Number(this.scrollHeightList[1]) + 120);
          break;
        default:
          console.log(state);
          break;
      }
    },
    handleScroll() {
      console.log(1);
      this.setScrollHeight();
      const scroll =
        document.documentElement.scrollTop || document.body.scrollTop;

      // console.log(scroll, this.scrollHeight);
      if (scroll <= this.scrollHeightList[0]) {
        this.featuresIndex = 0;
      } else if (
        scroll > this.scrollHeightList[0] &&
        scroll <= this.scrollHeightList[1]
      ) {
        this.featuresIndex = 1;
      } else if (scroll > this.scrollHeightList[1]) {
        this.featuresIndex = 2;
      }
    },
    changeActiveStage(key) {
      console.log(key);
      this.soulStageActive = key;
    },
    checkPartent(event) {
      console.log(event);
    },
    changeOccupation(key) {
      this.activeBallKey = key;
    },
    nextOccupation(value) {
      const { activeBallKey } = this;
      console.log(value, activeBallKey);
      if (value) {
        if (activeBallKey !== 3) {
          this.activeBallKey += 1;
        } else {
          this.activeBallKey = 0;
        }
      } else {
        if (activeBallKey !== 0) {
          this.activeBallKey -= 1;
        } else {
          this.activeBallKey = 3;
        }
      }
    },
    scrollOffset(index) {
      console.log(index);
      this.featuresIndex = index;
      switch (index) {
        case 0:
          this.$refs.scrollBarContent.scrollTo({ y: 1 }, 1500);
          break;
        case 1:
          this.$refs.scrollBarContent.scrollTo({ y: 700 }, 1500);
          break;
        case 2:
          this.$refs.scrollBarContent.scrollTo({ y: 1400 }, 1500);
          break;
        default:
          break;
      }
      // console.log(top);
    },
    handleMobileScroll(vertical) {
      // console.log();
      const height = window.document.body.offsetHeight / 3;
      const top = this.$refs.scrollTop.offsetTop - height;
      const bottom = this.$refs.scrollBottom.offsetTop - height;
      const scrollTop = vertical.scrollTop;
      // console.log(vertical.scrollTop,top,bottom);

      if(scrollTop < top) {
        this.featuresIndex = 0;
      } else if (scrollTop > top && scrollTop < bottom) {
        this.featuresIndex = 1;
      } else {
        this.featuresIndex = 2;
      }
    },
  },
  mounted() {
    this.increment({ status: 2 });

    // window, scrollTo(0, 1000);
    if (this.isMobile) {
      // console.log("isMobile");
      this.$nextTick(() => {
        const top0 = this.$refs.scroll0.offsetTop;
        const top1 = this.$refs.scroll1.offsetTop;
        const top2 = this.$refs.scroll2.offsetTop;
        console.log(top0,top1,top2);
      });
    } else {
      this.$nextTick(() => {
        window.addEventListener("scroll", this.handleScroll);
        const { query } = this.$route;
        if (query.position) {
          const position = parseInt(query.position);
          setTimeout(() => {
            const scrollHeight =
              document.body.scrollHeight - document.body.offsetHeight;
            // console.log("scrollHeight  query", scrollHeight);
            const list = [
              0,
              (scrollHeight * 0.2).toFixed(0),
              (scrollHeight * 0.7).toFixed(0),
            ];

            // console.log(list[position]);
            this.featuresIndex = position;
            window.scrollTo(0, list[position]);
          }, 500);
        } else {
          window.scrollTo(0, 0);
        }
      });
    }
  },
  watch: {},
};
</script>


<style lang="scss" scoped>
@media (min-width: 751px) {
  .container {
    background: url(../../assets/img/features/bg.png) no-repeat;
    background-size: cover;
    padding-top: 160px;
    // padding-top: 87px;
  }

  .features-container {
    // margin: 0 183px 70px 85px;
    .features-wrap {
      position: relative;
      // padding-bottom: 240px;
    }
  }
  .soul {
    // margin: 0 183px 70px 0;
    padding: 70px 0;
  }

  .activeStage {
    background: url(../../assets/img/features/activeStage.png) no-repeat right;
    background-size: contain;
    .stage {
      display: block;
    }
  }

  .features {
    position: fixed;
    top: 210px;
    right: 91px;
    height: 586px;
    width: 60px;
    background-image: url(../../assets/img/rope.png);
    padding-top: 80px;
    background-repeat: no-repeat;
    background-position: top center;
    z-index: 9;
    .features-item {
      font-size: 26px;
      color: #fff;
      text-align: center;
      height: 167px;
      box-sizing: border-box;
      background-size: cover;
      background-image: url(../../assets/img/features.png);
      background-size: cover;
      .features-name {
        display: inline-block;
        text-align: center;
        width: 26px;
        line-height: 33px;
        color: #fff;
        padding-top: 50px;
        cursor: pointer;
      }
    }
    .featuresStyle {
      background-image: url(../../assets/img/featuresCheck.png);
    }
    img {
      margin-left: 8px;
    }
  }
}
</style>

<style lang="scss" scoped>
@media (max-width: 750px) {
  .scroll-wrapper {
    height: 100vh;
    width: 100vw;
  }
  .container {
    background: url(../../assets/img/features/bg.png) no-repeat;
    background-size: cover;
    padding-top: 0.85rem;
    padding-bottom: 1.32rem;
    width: 100vw;
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    z-index: 1;
    // min-height: 150vh;
  }

  .features-container {
    // margin: 242px 183px 70px 85px;
    .features-wrap {
      position: relative;
      padding: 1rem 0 0;
    }
  }
  .features-content {
    position: relative;
  }

  .features-character {
    width: 80%;
    height: auto;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    // position: relative;
  }

  .gamePlay {
    position: absolute;
    top: 0.3rem;
    left: 45%;
    width: 1rem;
    height: 1rem;
    img {
      width: 100%;
    }
  }

  .features {
    position: fixed;
    top: 5rem;
    right: 0.1rem;
    // height: 2.5rem;
    width: 0.6rem;
    z-index: 30;
    // padding-top: .4rem;
    .features-item {
      font-size: 0.24rem;
      color: #fff;
      text-align: center;
      height: 1.6rem;
      box-sizing: border-box;
      background-size: cover;
      background-image: url(../../assets/img/features.png);
      background-size: cover;
      span {
        display: inline-block;
        text-align: center;
        width: 0.3rem;
        line-height: 0.33rem;
        padding-top: 0.5rem;
      }
    }
    .featuresStyle {
      background-image: url(../../assets/img/featuresCheck.png);
    }
    img {
      margin-left: 0.09rem;
      width: 0.3rem;
    }
  }
  .features::before {
    content: "";
    display: block;
    height: 0.4rem;
    background-image: url(../../assets/img/rope.png);
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 0.09rem 0.9rem;
  }
  .portraitIcon {
    bottom: 0.2rem;
    top: auto;
  }

  .arrow {
    position: absolute;
    top: 4.2rem;
    left: 1rem;
    background-image: url(../../assets/img/arrow.png);
    width: 5.5rem;
    height: 0.5rem;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 1;
  }
}
</style>